.container {
  max-width: 600px; /* Changed from width to max-width */
  width: 90%; /* Fallback for smaller screens */
  margin: 0 auto;
  padding: 20px;
  background-image: linear-gradient(180deg, #130754 0%, #3b2f80 100%);
  border-radius: 1em;
  min-height: 100vh;
  box-sizing: border-box;
}

.top-bar {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
  width: 100%;
  align-items: center;
}

.top-bar form {
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 500px;
  padding: 0 10px;
}

.top-bar input {
  flex: 1;
  width: 100%;
  height: 50px;
  background: #ebfffc;
  border: none;
  outline: none;
  border-radius: 30px;
  padding: 0 20px;
  font-size: 16px;
}

.search-icon {
  min-width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-items: center;
}

.weather-image {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.weather-image img {
  width: 150px;
  height: auto;
}

.weather-temp {
  font-size: clamp(2rem, 8vw, 4rem);
  color: white;
  text-align: center;
  margin: 10px 0;
}

.weather-location {
  font-size: clamp(1.5rem, 6vw, 2.5rem);
  color: white;
  text-align: center;
  margin: 10px 0;
}

.data-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  color: white;
  flex-wrap: wrap;
  padding: 20px 0;
}

.element {
  display: flex;
  align-items: center;
  gap: 15px;
  min-width: 150px;
}

.icon {
  width: 50px;
  height: auto;
}

.data {
  display: flex;
  flex-direction: column;
}

.humidity-percent,
.wind-rate {
  font-size: 1.5rem;
  font-weight: 400;
}

.text {
  font-size: 1rem;
  font-weight: 400;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .container {
    width: 95%;
    padding: 10px;
  }

  .top-bar form {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .top-bar input {
    height: 45px;
    font-size: 14px;
  }

  .search-icon {
    width: 45px;
    height: 45px;
  }

  .weather-image img {
    width: 120px;
  }

  .element {
    min-width: 120px;
    gap: 10px;
  }

  .humidity-percent,
  .wind-rate {
    font-size: 1.2rem;
  }

  .text {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .weather-image img {
    width: 100px;
  }

  .element {
    flex-direction: column;
    text-align: center;
  }

  .data {
    align-items: center;
  }
}
